@import "~bootstrap/scss/bootstrap";

.sqilet-footer-navbar{
    width: 100%;
    background-color:#000;
    height: auto;
    padding: 20px;
}

.sqilet-footer-navbar h4{
    margin-bottom: 10px;
    color: #fff;
}

.sqilet-header-navbar{
    background-color: #f4c430;
    width: 100%;
    height: 70px;
}

.header-nav a, .header-nav a:hover{
    color: #000;
}

.footer-pad a{
    text-decoration: none;
    color: #eee;
}
