@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$saffron: #f4c430;
$dark-blue: #00008B;
$white: #fff;
$silver: #f5f5f5;
$paper: #F3F1E0;
$salmon: Salmon;

// .loading.cover-content {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   -webkit-transform: translate(-50%, -50%);
//           transform: translate(-50%, -50%);
// }

// .loading.cover-page {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   -webkit-align-items: center;
//           align-items: center;
//   -webkit-justify-content: center;
//           justify-content: center;
// }

body{
  font-family: 'Noto Serif Display', serif !important;
}

.sqiletlogo{
  width: 150px;
  height: 150px;
  border-radius: 10%;
}
.app-content{
  background-color:#E8E8E8;
}
.btn-sqilet{
  border-radius: 1.875rem !important;
  border: 0rem;
  padding: auto;
  color: $white;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: auto !important;
  height: 3.125rem !important;
}

#marketingButton{
  width: 12.5rem !important;
  height: 3.125rem !important;
  background-color: $dark-blue;
}

.logo{
  background: none;
  border: none;
  width: 32px;
  height: 30px;
}

.marketing-section{
  background-color: $saffron !important;
  padding: 20px !important;
  margin: 0;
}

.marketing-section p{
  color: #000;
}

.marketing-section-title h1{
  font-size: 4rem;
  color : #000 !important;
}

.how-it-works{
  color: $salmon;
  background-color: $paper;
}

.how-it-works h2, .how-it-works h4{
  color: $salmon;
}

.how-it-works-heading{
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 1rem;
  color: #000;
}

.how-it-works-details{
  text-align: center;
  padding: 1.5rem;
  color: #000;
}


.how-it-works-details svg{
  margin-bottom: 1rem;
}

.what-we-provide{
  padding: 1rem;
  color: $white;
  height: auto !important;
  background-color: $salmon;
  margin: 0;
}

.what-we-provide h2, .what-we-provide h4, .what-we-provide p{
  color: $white;
}

.what-we-provide-heading{
  text-align: center;
  margin-bottom: 5rem;
}

.first-details-description,.second-details-description,.third-details-description{
  position: relative;
  margin-top: 6%;
  text-align: left;
}

.hero {
  height: 43.75rem;
}

.marketing-section-content{
  margin: 0;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.marketing-section-content h1{
  font-size: 4rem;
  font-weight: 300;
  color : #000 !important;
}

.pricing-section{
  margin: 0;
  background-color: #f3f3f3;
  text-align: center;
  padding: 10px;
}

.pricing-section h1{
  font-size: 8rem;
  margin-bottom: 0%;
}
.pricing-section h2{
  font-size: 2rem;
}

.class-types{
  margin: 0;
  background-color:#000041;
  padding: 20px;
  text-align: center;

}

.class-types h2{
  font-size: 2rem;
}

.class-types__title{
  margin-bottom: 8%;
}

.class-types__option-container{
  margin-bottom: 6%;
}

.class-types__title, .class-types__option, .class-types__text{
  color:#fff;

}
.class-types__descriptions{
  position: relative;
  margin-top: 6%;
  text-align: left;
  color: #fff;

}

.class-types-img{
  height: 16rem !important;
  width: 16rem !important;
  border-radius: 50% !important;
}

.categories{
  background-color:#F3F1E0;
  padding: 1.25rem;
  color: #000 !important;
}

.categories-title{
  text-align: center;
  font-size: 2rem;
}

.categories Button{
  border-width: 1px;
  border-color: #000;
  background: None;
}

.black_items{
  color: rgb(163, 157, 157) !important; 
}

.sqilet-legal-body{
  margin: 5%;
}
